<template>
  <FocusTrap>
    <div class="card">

    <WindowTitleBar title="Extrusion Delivery" @loadData="loadData" @close_window="closeThis" >
      {{setTitle()}}
    </WindowTitleBar>

    <div class="card-body">

      <div class="row">

        <div class="col-md-1">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Shift</label>
            <select id="cmbshift"  class="form-control" v-if="voucher"  v-model="voucher.shift_id"  autofocus>
              <option v-for="shift in shifts" v-bind:value="shift.id">
                {{ shift.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Machine</label>
            <select class="form-control" v-if="voucher"  v-model="voucher.mac_id" >
              <option v-for="machine in machines" v-bind:value="machine.id">
                {{ machine.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Operator</label>
            <select class="form-control" v-if="voucher"  v-model="voucher.emp_id" >
              <option v-for="emp in emps" v-bind:value="emp.id">
                {{ emp.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" >
          </div>
        </div>

      </div>

<!--      <div class="row">-->


<!--        <div class="col-md-2">-->
<!--          <div class="form-group form-group-material ">-->
<!--            <label class="control-label text-semibold">Quality</label>-->
<!--            <select class="form-control" v-if="voucher" v-model="voucher.quality.id" >-->
<!--              <option v-for="quality in qualities" v-bind:value="quality.id">-->
<!--                {{ quality.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-md-2">-->
<!--          <div class="form-group form-group-material ">-->
<!--            <label class="control-label text-semibold">Color</label>-->
<!--            <select class="form-control" v-if="voucher" v-model="voucher.color.id"  >-->
<!--              <option v-for="color in colorcharts" v-bind:value="color.id">-->
<!--                {{ color.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-md-5">-->
<!--        </div>-->

<!--        <div class="col-md-3">-->
<!--        </div>-->

<!--      </div>-->

      <div class="row">
        <div class="col-md-12">

          <!-- Basic layout-->
          <div class="card">


            <div class="card-body">
              <div class="row">
                <!--  Detail Table -->
                <div class="table-responsive">

                  <table id="mytable" class="table table-no-bordered">
                    <thead style="background-color: lightgrey">
                    <tr>
                      <th style="width:50px;">S.No</th>
                      <th >Raw Materials</th>
                      <th >Description</th>
                      <th style="width:150px; text-align: right;">Weight</th>
                      <th style="width: 30px;">Action</th>
                    </tr>

                    </thead>
                    <tbody >
                      <tr v-model="voucher" v-for="(detail,index) in voucher.list" >
                        <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                        <td style="padding: 0px;">
                          <select class="form-control" v-if="detail" v-model="detail.item_id" >
                            <option v-for="item in detailItems" v-bind:value="item.id">
                              {{ item.name }}
                            </option>
                          </select>
                        </td>
                        <td style="padding: 0px;">
                          <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.desc" />
                        </td>
                        <td style="padding: 0px;">
                          <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.weight" @change="sumRowAmountTotal" />
                        </td>
                        <td style="padding: 0px;text-align: right">
                          <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td style="padding: 0px;">
                          <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                            <i class="icon-plus3"></i>
                          </button>
                        </td>
                        <td></td>
                        <td></td>

                        <td class="text-right"  style="padding-right: 15px;"> <span >{{ rowTotalWeight | formatWeight }}</span></td>

                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <!-- / Detail Table -->
              </div>
            </div>
          </div>
          <!-- /basic layout -->

        </div>


      </div>


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">

        </div>

        <div class="col-md-3 text-right">

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import WindowTitleBar from '../../../components/core/WindowTitleBar'

  export default {
    name: 'ExtrusionDeliveryForm',
    store,
    components: {
      WindowTitleBar
    },
    props: {
      VoucherType: {
        type: Number,
        default: 0
      },
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[{"id":0,"master_id":0,"item_id":0,"desc":"","weight":0,"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0}}]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    },
    data () {
      return {
        machines:[],
        shifts:[],
        emps:[],
        detailItems:[],
        qualities:[],
        colorcharts:[],
        items : new Map(),
        rowTotalWeight: 0.0,
        voucher: JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[{"id":0,"master_id":0,"item_id":0,"desc":"","weight":0,"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0}}]}'),
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      self.$data.shifts.push({'id':2,'name':'Day'});
      self.$data.shifts.push({'id':3,'name':'Night'});

      if( self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01' ) {
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }
      self.loadData();
      $('#cmbshift').focus();
    },
    watch: {
      'voucher.list': {
        handler (newValue, oldValue) {
          // newValue.forEach((item) => {
          //   console.log(item);
          // });
        },
        deep: true
      }
    },
    filters:{
      formatWeight(val){
        return val.toFixed(3);
      }
    },
    methods:{
      setTitle() {
        return this.voucher.id == 0 ? "Extrusion Delivery" : "Doc No: " + this.voucher.doc_no + "  Extrusion Delivery Updation";
      },
      closeThis(){
        this.$emit('extrusion_delivery_window_closed');

        if(this.voucher.id > 1){
          this.$modal.hide('extrusion_delivery_window');
          this.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[{"id":0,"master_id":0,"item_id":0,"desc":"","weight":0,"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0}}]}');
        }else{
          this.$router.push("/")
        }
      },
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      addRow(){
        const self = this;
        self.$data.voucher.list.push(JSON.parse('{"item_id":0,"item":{"id":0,"nature":0,"name":""},"desc":"","weight":0.0}'));
        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.rowTotalWeight = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowTotalWeight += parseFloat(detail.weight);
        });
      },
      clear(){
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[{"id":0,"master_id":0,"item_id":0,"desc":"","weight":0,"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0}}]}');
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.containers = [];
        self.$data.rowTotalWeight = 0.0;

        $('#cmbshift').focus();
      },
      loadData() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.machines = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.machines = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:1500});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error", timer:1500});
        });


        self.$data.emps = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(  _.isArray(resp.data)){
              self.$data.emps = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:1500});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error", timer:1500});
        });

        //Fetch Detail Ledgers
        self.$data.detailItems = [];
        self.$data.items = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}api/items/rawmaterials`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(resp.data != null){
              self.$data.detailItems = resp.data;
              self.$data.detailItems.forEach(function (itm) {
                self.$data.items.set(itm.id, itm);
              });
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:1500});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString() ,  type: "error", timer:1500});
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        const self = this;

        self.$data.voucher.finyear = self.$store.state.user.finyear;
        self.$data.voucher.status = "Active";
        self.$data.voucher.cmp_id = self.$store.state.user.company.id;
        self.$data.voucher.emp_id = parseInt(self.$data.voucher.emp_id);
        self.$data.voucher.shift_id = parseInt(self.$data.voucher.shift_id);
        self.$data.voucher.mac_id = parseInt(self.$data.voucher.mac_id);

        let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher))
        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

        if(self.$data.voucher.mac_id < 1){
          alert("Invalid Machine");
          return
        }else if (self.$data.voucher.shift_id < 1){
          alert("Invalid Shift");
          return
        }else if (self.$data.voucher.emp_id < 1) {
          alert("Invalid Operator");
          return
        // }else if (self.$data.voucher.quality.id < 1) {
        //   alert("Invalid Quality");
        //   return
        // }else if (self.$data.voucher.color.id < 1) {
        //   alert("Invalid Color");
        //   return
        }else if(self.$data.voucher.list.length == 0){
          alert("Invalid Details");
          return
        }else {

          myvoucher.list.forEach(function (det) {

            if(det.item_id < 1){
              alert("Invalid Item");
              return
            }else if (det.weight <= 0){
              alert("Invalid Weight");
              return
            }

            det.weight = parseFloat(det.weight);
          });

        }


        const requestOptions = {
          method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };


        $('#card').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/extrusion/delivery`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500,onClose: () => {
                $('#cmbshift').focus();
              }} );
            self.$emit('delivery_saved', resp.data);
            self.$data.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[{"id":0,"master_id":0,"item_id":0,"desc":"","weight":0,"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0}}]}');
            self.clear();

          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", timer:1500} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error", timer:1500} );
        }).finally(function (){
          $('#card').unblock();
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
